'use client';

import { useState } from 'react';
import Link from '../Link/Link';
import Conditional from '@next-app/components/Conditional';
import dynamic from 'next/dynamic';

const HTMLContentModal = dynamic(
  () =>
    import('@next-app/components/elements/HTMLContentModal/HTMLContentModal'),
  { ssr: false },
);

interface SeeDetailsLinkModalProps {
  className?: string;
  linkText?: string;
  url: string;
  title: string;
  modalId: string;
  s_objectId?: string;
  s_regionId?: string;
  triggerElement?: React.ReactNode; // Optional custom trigger (like a button or image)
}

const SeeDetailsLinkModal = (props: SeeDetailsLinkModalProps) => {
  const {
    triggerElement,
    linkText,
    className,
    url,
    title,
    modalId,
    s_objectId,
    s_regionId,
  } = props;

  const [modalUrl, setModalUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [showEyebrowModal, setShowEyebrowModal] = useState(false);

  const handleClick = () => {
    setModalTitle(title);
    setModalUrl(url);
    setShowEyebrowModal(true);
  };

  const elemAttributes = {
    s_objectid: s_objectId || '',
    s_regionid: s_regionId || '',
  };

  return (
    <>
      {/* Trigger Element */}
      {triggerElement ? (
        <div
          onClick={() => {
            handleClick();
          }}
          data-bs-toggle="modal"
          // data-bs-target={`#${modalId}`}
          data-bs-target="#dynamicModalWrapper"
          className={className}
          {...elemAttributes}
        >
          {triggerElement}
        </div>
      ) : (
        <Link
          className={className}
          href=""
          onClick={() => {
            handleClick();
          }}
          data-bs-toggle="modal"
          // data-bs-target={`#${modalId}`}
          data-bs-target="#dynamicModalWrapper"
          {...elemAttributes}
        >
          {linkText}
        </Link>
      )}

      {/* Modal Component */}
      <Conditional if={showEyebrowModal}>
        <HTMLContentModal
          url={modalUrl}
          modalTitle={modalTitle}
          setShowEyebrowModal={setShowEyebrowModal}
          modalId="microBannerSeeDetailsModal"
        />
      </Conditional>
      {/* <HTMLContentModal
        url={modalUrl}
        modalTitle={modalTitle}
        modalId={modalId}
      /> */}
    </>
  );
};

export default SeeDetailsLinkModal;
