'use client';
import Conditional from '@next-app/components/Conditional';
import {
  hideExtraBackdrops,
  isNotNullOrUndefined,
} from '@next-app/utils/common-utils';
import { forwardRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Heading from '../Heading/Heading';
import classes from './dynamic-modal.module.scss';
import Button from '../Button/Button';

interface DynamicModalProps {
  modalContentClass?: string;
  centerClass?: string;
  modalTitle?: any;
  headingTagName?: '1' | '2' | '3' | '4' | '5' | '6';
  onModalClose?: Function;
  children?: any;
}

const DynamicModal = forwardRef((props: DynamicModalProps, ref: any) => {
  const {
    modalContentClass,
    centerClass,
    modalTitle,
    headingTagName = '4',
    onModalClose,
  } = props;
  const portalDiv = document.getElementById('dynamicModal')!;

  useEffect(() => {
    const myModal = document.getElementById('dynamicModalWrapper');
    hideExtraBackdrops();
    myModal?.addEventListener('hidden.bs.modal', () => {
      if (onModalClose) {
        onModalClose();
        document.body.style.overflow = 'auto';
      }
    });

    return () => {
      myModal?.removeEventListener('hidden.bs.modal', () => {
        console.log('Event listener removed');
      });
    };
  }, [portalDiv, onModalClose]);

  return (
    <>
      {createPortal(
        <div className={centerClass || ''}>
          <div className={`${modalContentClass} ${classes.modalContent || ''}`}>
            <Conditional if={isNotNullOrUndefined(modalTitle)}>
              <div className={classes['modal-header']}>
                <Heading
                  tagName={headingTagName || '3'}
                  customClass={`${classes.modalTitle || ''}`}
                >
                  {modalTitle}
                </Heading>

                <Button
                  data-testid="close-modal-button"
                  type="button"
                  customClass={`${classes.closeIcon || ''}`}
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  data-dismiss="dynamicModalWrapper"
                ></Button>
              </div>
            </Conditional>

            <div className={`${classes.modalBody || ''}`}>{props.children}</div>
          </div>
        </div>,
        portalDiv,
      )}
    </>
  );
});
DynamicModal.displayName = 'DynamicModal';
export default DynamicModal;
