'use client';

import { PageLoadDTMEvent } from '@next-app/utils/dtm-event';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SeoItem } from '@next-app/interface/Common/index';

interface IPageData {
  title?: string;
  pageName?: string;
}

const PageLoadDTM = (props?: any) => {
  const { init, sessionInfo, seoData } = useSelector(
    (state: any) => state.init,
  );
  const [isSendDTMLoaded, setIsSendDTMLoaded] = useState(false);

  const {
    productFacets = {},
    assFacets = {},
    productPLPPageDetails = {},
    assPLPPageDetails = {},
    seoItem = {},
  } = props || {};

  const isEventFired = useRef(false);

  useEffect(() => {
    let loadDtmEventTimeout: ReturnType<typeof setTimeout>;
    const checkIfDTMEventIsLoaded = () => {
      if ((window as any).sendDTMEvent) {
        setIsSendDTMLoaded(true);
        clearInterval(loadDtmEventTimeout);
      }
    };

    loadDtmEventTimeout = setInterval(checkIfDTMEventIsLoaded, 250);

    return () => {
      clearInterval(loadDtmEventTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasData =
      Object.keys(sessionInfo).length > 0 &&
      Object.keys(init?.initResponse).length > 0;

    if (
      isSendDTMLoaded &&
      typeof window !== 'undefined' &&
      window.sendDTMEvent &&
      hasData &&
      !isEventFired.current
    ) {
      const { sessionInfo: info } = sessionInfo;
      const { profile } = info;

      const currentUrl = window?.location?.pathname || '/';
      const seoUrl = currentUrl?.split('+')?.length
        ? currentUrl?.split('+')[0]
        : currentUrl;

      const isStore = Object.keys(profile?.selectedStore).length > 0;
      let pageData: IPageData = {};
      if (seoItem?.url?.includes(seoUrl)) {
        pageData = seoItem;
      } else if (seoData && seoData?.length) {
        pageData =
          seoData?.find((item: SeoItem) => item?.url?.includes(seoUrl)) || {};
      }

      const data = {
        storeSelected: isStore
          ? `${profile?.selectedStore?.city}, ${profile?.selectedStore?.state}`
          : '',
        llmAPIVersion: init?.initResponse?.internalAPI,
        email: profile?.email || '',
        profileId: profile?.id || '',
        isGSAUser: profile?.gsaUser,
        userShopFor: profile?.userShopFor || '',
        isFurniture: info?.furniture,
        md5Email: profile?.md5Email || '',
        currentUrl: currentUrl,
        rewardsUserStatus: info?.rewardsCustomer?.tierInfo?.currentTier
          ? info?.rewardsCustomer?.tierInfo?.currentTier
          : info?.rewardsCustomer?.isRewardsMember &&
              profile?.securityStatus >= 4
            ? 'Signed In'
            : 'Guest',
        orderId: info?.cartInfo?.cartId,
        cartCount: info?.cartInfo?.cartQty,
        recognized: profile?.loginStatus === 'recognized',
        securityStatus: profile?.securityStatus,
        pageTitle: pageData?.title || '',
        pageName: pageData?.pageName || pageData?.title || '',
        pageSection: currentUrl === '/' ? 'home' : 'category',
        pageType: currentUrl === '/' ? 'homepage' : 'category',
        pageUrl: currentUrl,
        billingAddress: {
          country: profile?.selectedStore?.city,
          postalCode: profile?.selectedStore?.postalCode,
          state: profile?.selectedStore?.state,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
        },
        findingMethod: currentUrl === '/' ? '' : 'browse',
        ...(profile.loginStatus !== 'guest' && {
          profileDetails: {
            dateOfBirth: profile.dateOfBirth,
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            isGsaUser: profile.gsaUser,
          },
        }),
        ...productFacets,
        ...assFacets,
        ...productPLPPageDetails,
        ...assPLPPageDetails,
      };

      PageLoadDTMEvent(data);
      isEventFired.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, sessionInfo, isSendDTMLoaded]);

  return null;
};

export default PageLoadDTM;
